import axios from 'axios';
import { fetchallProperty } from './dCandidate';

const baseUrl = "https://localhost:7153/api/Front/"

export default {
    dCandidate(url = baseUrl){
        console.log(url + "GetHome?token=" + process.env.REACT_APP_APP_TOKEN)
        return {
            fetchAll        : () => axios.get(url + "GetHome?token=" + process.env.REACT_APP_APP_TOKEN),
            fetchAllAbout   : () => axios.get(url + "GetAbout?token=" + process.env.REACT_APP_APP_TOKEN),
            fetchAllProperty   : () => axios.get(url + "GetProperties?token=" + process.env.REACT_APP_APP_TOKEN),
            fetchAllContact   : () => axios.get(url + "GetContact?token=" + process.env.REACT_APP_APP_TOKEN),
            fetchAllPropertyByName   : name => axios.get(url + "GetProjectDetails?name="+name.name+"&token=" + process.env.REACT_APP_APP_TOKEN),
            fecthById   : id => axios.get(url + id),
            create      : newRecord => axios.post(url, newRecord),
            update      : (id, updateRecord) => axios.put(url + id, updateRecord),
            delete      : id => axios.delete(url + id)
        }
    }
}