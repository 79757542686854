import React, { Fragment } from 'react';
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../actions/dCandidate";
import anime from 'animejs';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

const Events = (props) => {
    useEffect(() => {
        anime({
            targets: '[data-anime]', // Select elements with the data attribute
            el: "childs", translateY: [50, 0], opacity: [0, 1], duration: 600, delay: 0, staggervalue: 300, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-1]', // Select elements with the data attribute
            translateY: [-50, 0], opacity: [0, 1], duration: 600, delay: 0, staggervalue: 300, easing: "easeOutQuad"
        });
    }, [])

    return (
        <Fragment>
            <section className="top-space-margin page-title-big-typography border-radius-6px lg-border-radius-0px p-0" data-parallax-background-ratio="0.5" style={{ backgroundImage: "url(assets/images/others/sadhana.jpeg)" }}>
                <div className="opacity-extra-medium bg-blue-whale"></div>
                <div className="container">
                    <div className="row align-items-center justify-content-center small-screen">
                        <div className="col-lg-8 position-relative text-center page-title-extra-large" data-anime>
                            <h1 className="m-auto text-white text-shadow-double-large fw-600 ls-minus-2px">Events</h1>
                        </div>
                        <div className="down-section text-center" data-anime-1>
                            <a href="#down-section" className="section-link">
                                <div className="text-white">
                                    <i className="feather icon-feather-chevron-down icon-very-medium"></i>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pt-0 ps-7 pe-7 xl-ps-2 xl-pe-2 xs-px-0">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <ul className="blog-simple blog-wrapper grid-loading grid grid-4col xl-grid-4col lg-grid-3col md-grid-2col sm-grid-2col xs-grid-1col gutter-extra-large">
                                <li className="grid-sizer"></li>
                                {/* start blog item */}
                                <li className="grid-item">
                                    <figure className="position-relative mb-0 box-hover">
                                        <div className="blog-image">
                                            <img src="assets/images/events/pitrutarpan.jpeg" alt="" />
                                            <span className="box-overlay bg-dark-slate-blue"></span>
                                            <span className="bg-gradient-gray-light-dark-transparent position-absolute opacity-6 top-0px left-0px w-100 h-100"></span>
                                        </div>
                                        <figcaption className="d-flex flex-column h-100">
                                            <div className="my-auto w-100 text-center blog-hover-icon">
                                                <Link className="d-inline-block" to={"/pitru-dosh-nivaran-vidhi"}><i className="line-icon-Arrow-OutRight icon-extra-large text-white"></i></Link></div>
                                            <div className="position-relative post-content p-14 text-center last-paragraph-no-margin">
                                                <div className="position-relative z-index-2 overflow-hidden">
                                                    <Link className="d-inline-block fs-15 text-gradient-light-blue-light-turquoise mb-5px text-uppercase fw-600" to={"/pitru-dosh-nivaran-vidhi"}>30 August 2023</Link>
                                                    <Link className="card-title fs-20 alt-font fw-500 text-white mb-0 d-block" to={"/pitru-dosh-nivaran-vidhi"}>पितृ दोष निवारण विधी</Link>
                                                </div>
                                                <div className="box-overlay bg-dark-slate-blue"></div>
                                            </div>
                                        </figcaption>
                                    </figure>
                                </li>
                                <li className="grid-item">
                                    <figure className="position-relative mb-0 box-hover">
                                        <div className="blog-image">
                                            <img src="assets/images/events/gurupornima_1.jpeg" alt="" />
                                            <span className="box-overlay bg-dark-slate-blue"></span>
                                            <span className="bg-gradient-gray-light-dark-transparent position-absolute opacity-6 top-0px left-0px w-100 h-100"></span>
                                        </div>
                                        <figcaption className="d-flex flex-column h-100">
                                            <div className="my-auto w-100 text-center blog-hover-icon">
                                                <Link className="d-inline-block" to={"/gurupornima2024"}><i className="line-icon-Arrow-OutRight icon-extra-large text-white"></i></Link></div>
                                            <div className="position-relative post-content p-14 text-center last-paragraph-no-margin">
                                                <div className="position-relative z-index-2 overflow-hidden">
                                                    <Link className="d-inline-block fs-15 text-gradient-light-blue-light-turquoise mb-5px text-uppercase fw-600" to={"/gurupornima2024"}>30 August 2024</Link>
                                                    <Link className="card-title fs-20 alt-font fw-500 text-white mb-0 d-block" to={"/gurupornima2024"}>गुरुपौर्णिमा उत्सव आणि सत्संग साधना सोहळा</Link>
                                                </div>
                                                <div className="box-overlay bg-dark-slate-blue"></div>
                                            </div>
                                        </figcaption>
                                    </figure>
                                </li>
                                <li className="grid-item">
                                    <figure className="position-relative mb-0 box-hover">
                                        <div className="blog-image">
                                            <img src="assets/images/events/mahashivratri.jpeg" alt="" />
                                            <span className="box-overlay bg-dark-slate-blue"></span>
                                            <span className="bg-gradient-gray-light-dark-transparent position-absolute opacity-6 top-0px left-0px w-100 h-100"></span>
                                        </div>
                                        <figcaption className="d-flex flex-column h-100">
                                            <div className="my-auto w-100 text-center blog-hover-icon">
                                            <Link className="d-inline-block" to={"/mahashivratri2023"}><i className="line-icon-Arrow-OutRight icon-extra-large text-white"></i></Link></div>
                                            <div className="position-relative post-content p-14 text-center last-paragraph-no-margin">
                                                <div className="position-relative z-index-2 overflow-hidden">
                                                    <Link className="d-inline-block fs-15 text-gradient-light-blue-light-turquoise mb-5px text-uppercase fw-600" to={"/mahashivratri2023"}>30 August 2024</Link>
                                                    <Link className="card-title fs-20 alt-font fw-500 text-white mb-0 d-block" to={"/mahashivratri2023"}>महाशिवरात्री जागरण साधना</Link>
                                                </div>
                                                <div className="box-overlay bg-dark-slate-blue"></div>
                                            </div>
                                        </figcaption>
                                    </figure>
                                </li>
                                <li className="grid-item">
                                    <figure className="position-relative mb-0 box-hover">
                                        <div className="blog-image">
                                            <img src="assets/images/events/vishnuyag.jpeg" alt="" />
                                            <span className="box-overlay bg-dark-slate-blue"></span>
                                            <span className="bg-gradient-gray-light-dark-transparent position-absolute opacity-6 top-0px left-0px w-100 h-100"></span>
                                        </div>
                                        <figcaption className="d-flex flex-column h-100">
                                            <div className="my-auto w-100 text-center blog-hover-icon">
                                            <Link className="d-inline-block" to={"/vishnuyag2023"}><i className="line-icon-Arrow-OutRight icon-extra-large text-white"></i></Link></div>
                                            <div className="position-relative post-content p-14 text-center last-paragraph-no-margin">
                                                <div className="position-relative z-index-2 overflow-hidden">
                                                    <Link className="d-inline-block fs-15 text-gradient-light-blue-light-turquoise mb-5px text-uppercase fw-600" to={"/vishnuyag2023"}>30 August 2023</Link>
                                                    <Link className="card-title fs-20 alt-font fw-500 text-white mb-0 d-block" to={"/vishnuyag2023"}>श्री विष्णू याग साधना</Link>
                                                </div>
                                                <div className="box-overlay bg-dark-slate-blue"></div>
                                            </div>
                                        </figcaption>
                                    </figure>
                                </li>
                                <li className="grid-item">
                                    <figure className="position-relative mb-0 box-hover">
                                        <div className="blog-image">
                                            <img src="assets/images/events/sankraman/sankraman_1.jpeg" alt="" />
                                            <span className="box-overlay bg-dark-slate-blue"></span>
                                            <span className="bg-gradient-gray-light-dark-transparent position-absolute opacity-6 top-0px left-0px w-100 h-100"></span>
                                        </div>
                                        <figcaption className="d-flex flex-column h-100">
                                            <div className="my-auto w-100 text-center blog-hover-icon">
                                            <Link className="d-inline-block" to={"/sankraman"}><i className="line-icon-Arrow-OutRight icon-extra-large text-white"></i></Link></div>
                                            <div className="position-relative post-content p-14 text-center last-paragraph-no-margin">
                                                <div className="position-relative z-index-2 overflow-hidden">
                                                    <Link className="d-inline-block fs-15 text-gradient-light-blue-light-turquoise mb-5px text-uppercase fw-600" to={"/sankraman"}>30 August 2023</Link>
                                                    <Link className="card-title fs-20 alt-font fw-500 text-white mb-0 d-block" to={"/sankraman"}>संक्रमण २०२३</Link>
                                                </div>
                                                <div className="box-overlay bg-dark-slate-blue"></div>
                                            </div>
                                        </figcaption>
                                    </figure>
                                </li>
                                <li className="grid-item">
                                    <figure className="position-relative mb-0 box-hover">
                                        <div className="blog-image">
                                            <img src="assets/images/events/level1/level1_1.jpeg" alt="" />
                                            <span className="box-overlay bg-dark-slate-blue"></span>
                                            <span className="bg-gradient-gray-light-dark-transparent position-absolute opacity-6 top-0px left-0px w-100 h-100"></span>
                                        </div>
                                        <figcaption className="d-flex flex-column h-100">
                                            <div className="my-auto w-100 text-center blog-hover-icon">
                                            <Link className="d-inline-block" to={"/level12022"}><i className="line-icon-Arrow-OutRight icon-extra-large text-white"></i></Link></div>
                                            <div className="position-relative post-content p-14 text-center last-paragraph-no-margin">
                                                <div className="position-relative z-index-2 overflow-hidden">
                                                    <Link className="d-inline-block fs-15 text-gradient-light-blue-light-turquoise mb-5px text-uppercase fw-600" to={"/level12022"}>30 August 2022</Link>
                                                    <Link className="card-title fs-20 alt-font fw-500 text-white mb-0 d-block" to={"/level12022"}>श्री मंत्रशास्त्र कार्यशाळा 1</Link>
                                                </div>
                                                <div className="box-overlay bg-dark-slate-blue"></div>
                                            </div>
                                        </figcaption>
                                    </figure>
                                </li>
                                {/* end blog item */}
                            </ul>
                        </div>
                        <div className="col-12 mt-5 d-flex justify-content-center">
                            <ul className="pagination pagination-style-01 fs-13 fw-500 mb-0">
                                <li className="page-item"><a className="page-link" href="#"><i className="feather icon-feather-arrow-left fs-18 d-xs-none"></i></a></li>
                                <li className="page-item"><a className="page-link" href="#">01</a></li>
                                <li className="page-item active"><a className="page-link" href="#">02</a></li>
                                <li className="page-item"><a className="page-link" href="#">03</a></li>
                                <li className="page-item"><a className="page-link" href="#">04</a></li>
                                <li className="page-item"><a className="page-link" href="#"><i className="feather icon-feather-arrow-right fs-18 d-xs-none"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}
export default connect()(Events);