import { applyMiddleware, compose, createStore } from "redux";
import { thunk } from "redux-thunk";
import { reducers } from "../reducers";
import { createBrowserHistory, History } from "history";
import { configureStore } from "@reduxjs/toolkit";
// import selectionReducer from "../reducers/selection";
// import articlesReducer from "../reducers/articles";
// import todosReducer, { I_TodoState } from "../reducers/todos";
import { routerMiddleware, connectRouter, RouterState } from "connected-react-router";
import { dCandidate } from "../reducers/dCandidate";
// export const store = createStore(
//     reducers,
//     compose (
//         applyMiddleware(thunk),
//         window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
//     )
// ) 

export const history = createBrowserHistory();

// combineReducers will be handled internally by configureStore
const rootReducer = (history: History<any>) => ({
    //   articles: articlesReducer,
    //   selection: selectionReducer,
    //   todos: todosReducer,
    dCandidate: dCandidate,
    router: connectRouter(history)
});

const preloadedState = {};
export const store = configureStore({
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(routerMiddleware(history)),
    reducer: rootReducer(history),

    preloadedState
});