import logo from './logo.svg';
//import './App.css';
import { store } from "./actions/store";
import { Provider } from 'react-redux';
//import DCandidates from './components/DCandidates';
import Header from './Header';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import { BrowserRouter, Route, Link, Routes } from 'react-router-dom';
import Sadhanas from './components/Sadhanas';
import Events from './components/Events';
import Level1 from './components/Level1';

function App() {
  return (
    <Provider store={store}>
      {/* <Header></Header> */}
        <Routes>
          <Route path="/" index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/level-1" element={<Level1 />} />
          <Route path="/sadhanas" element={<Sadhanas />} />
          <Route path="/events" element={<Events />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
    </Provider>
  );
}

export default App;
