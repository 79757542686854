import { type } from "@testing-library/user-event/dist/type"
import api from "./api";

export const ACTION_TYPES = {
    CREATE      : 'CREATE',
    UPDATE      : 'UPDATE',
    DELETE      : 'DELETE',
    FETCH_ALL   : 'FETCH_ALL',
    FETCH_ABOUT : 'FETCH_ABOUT',
    FETCH_PROPERTY  : 'FETCH_PROPERTY',
    FETCH_CONTACT   : 'FETCH_CONTACT',
    FETCH_PROPERTY_DETAILS  : 'FETCH_PROPERTY_DETAILS'
}

export const fetchall = () => dispatch =>
    {
        api.dCandidate().fetchAll()
        .then(
            response => {
                console.log(response.data)
                dispatch({
                    type: ACTION_TYPES.FETCH_ALL,
                    payload: response.data
                })        
            }
        )
        .catch(err => console.log(err))
    }

    export const fetchallAbout = () => dispatch =>
    {
        api.dCandidate().fetchAllAbout()
        .then(
            response => {
                console.log(response.data)
                dispatch({
                    type: ACTION_TYPES.FETCH_ABOUT,
                    payload: response.data
                })        
            }
        )
        .catch(err => console.log(err))
    }
    
    export const fetchallProperty = () => dispatch =>
    {
        api.dCandidate().fetchAllProperty()
        .then(
            response => {
                console.log(response.data)
                dispatch({
                    type: ACTION_TYPES.FETCH_PROPERTY,
                    payload: response.data
                })        
            }
        )
        .catch(err => console.log(err))
    }

    export const fetchallContact = () => dispatch =>
        {
            api.dCandidate().fetchAllContact()
            .then(
                response => {
                    console.log(response.data)
                    dispatch({
                        type: ACTION_TYPES.FETCH_CONTACT,
                        payload: response.data
                    })        
                }
            )
            .catch(err => console.log(err))
        }

    export const fetchallpropertyByName = (name) => dispatch =>
        {
            api.dCandidate().fetchAllPropertyByName(name)
            .then(
                response => {
                    console.log(response.data)
                    dispatch({
                        type: ACTION_TYPES.FETCH_PROPERTY_DETAILS,
                        payload: response.data
                    })        
                }
            )
            .catch(err => console.log(err))
        }