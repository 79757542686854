import React, { Fragment } from 'react';
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../actions/dCandidate";
import anime from 'animejs';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

const Sadhanas = (props) => {
    const [propertyData, setPropertyData] = useState([]);
    const [pageData, setPageData] = useState([]);
    const [pageImage, setPageImage] = useState("");
    const [stepImage, setStepImage] = useState("");
    const Navigate = useNavigate();
    useEffect(() => {
        props.fetchData();
        anime({
            targets: '[data-anime]', // Select elements with the data attribute
            el: "childs", translateY: [50, 0], opacity: [0, 1], duration: 600, delay: 0, staggervalue: 300, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-1]', // Select elements with the data attribute
            translateY: [-50, 0], opacity: [0, 1], duration: 600, delay: 0, staggervalue: 300, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-2]', // Select elements with the data attribute
            translateY: [30, 0], opacity: [0, 1], duration: 600, delay: 0, staggervalue: 300, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-3]', // Select elements with the data attribute
            e1: "childs", translateY: [0, 0], opacity: [0, 1], duration: 1200, delay: 150, staggervalue: 300, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-4]', // Select elements with the data attribute
            translateY: [30, 0], opacity: [0, 1], duration: 600, delay: 0, staggervalue: 300, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-5]', // Select elements with the data attribute
            e1: "childs", translateY: [0, 0], opacity: [0, 1], duration: 1200, delay: 150, staggervalue: 300, easing: "easeOutQuad"
        });
    }, [])

    function CheckImage(path) {
        axios.get(path).then(() => { return true; }).catch(() => { return false; });
    }

    function remove_n_words(words, n) {
        if (words != null) {
            return n === 0 ? words : words.split(" ").slice(0, -n).join(" ");
        }
    }

    function getLastNWords(str, n) {
        if (str != null) {
            const words = str.split(' ');
            if (words.length < n) {
                return str; // Return the original string if there are fewer than 2 words
            }
            return words.slice(-n).join(' ');
        }
    }

    // useEffect(() => {
    //     const makeRequest = async () => {
    //         let jsn = await props.data;
    //         if (jsn != null) {
    //             if (jsn.Properties != null) {
    //                 let parsedArray = JSON.parse(jsn.Properties);
    //                 setPropertyData(parsedArray);
    //             }
    //             if (jsn.Property != null) {
    //                 if (JSON.parse(jsn.Property).length) {
    //                     setPageData(JSON.parse(jsn.Property)[0]);
    //                     setPageImage(process.env.REACT_APP_API_HOST + "/Property/" + pageData.PropertyImage);
    //                     setStepImage(process.env.REACT_APP_API_HOST + "/Property/" + pageData.StepImage);
    //                 }
    //             }
    //         }
    //     }
    //     makeRequest();
    // }, [props.data]);

    return (
        <Fragment>
            <section className="top-space-margin page-title-big-typography border-radius-6px lg-border-radius-0px p-0"
                data-parallax-background-ratio="0.5" style={{ backgroundImage: "url(assets/images/others/sadhana.jpeg)" }}>
                <div className="opacity-extra-medium bg-blue-whale"></div>
                <div className="container">
                    <div className="row align-items-center justify-content-center small-screen">
                        <div className="col-lg-8 position-relative text-center page-title-extra-large" data-anime>
                            <h1 className="m-auto text-white text-shadow-double-large fw-600 ls-minus-2px">Sadhanas</h1>
                        </div>
                        <div className="down-section text-center"
                            data-anime-1>
                            <a href="#down-section" className="section-link">
                                <div className="text-white">
                                    <i className="feather icon-feather-chevron-down icon-very-medium"></i>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-solitude-blue">
                <div className="container">
                    <div className="row justify-content-center mb-4">
                        <div className="col-xl-7 col-lg-9 col-md-10 text-center">
                            <h3 className="alt-font text-dark-gray fw-600 ls-minus-1px"
                                data-anime-2>
                                Our various sadhanas</h3>
                        </div>
                    </div>
                    <div className="row align-items-center"
                        data-anime-3>
                        <div className="col-xl-3 col-lg-4 col-md-12 tab-style-05 md-mb-30px sm-mb-20px">
                            {/* start tab navigation */}
                            <ul className="nav nav-tabs justify-content-center border-0 text-left fw-500 fs-8 alt-font">
                                <li className="nav-item"><a data-bs-toggle="tab" href="#tab_karya1"
                                    className="nav-link d-flex align-items-center active"><i
                                        className="feather icon-feather-compass icon-extra-medium text-dark-gray"></i><span>श्री
                                            मंत्रशास्त्र कार्यशाळा 1</span></a></li>
                                <li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#tab_karya2"><i
                                    className="feather icon-feather-compass icon-extra-medium text-dark-gray"></i><span>श्री
                                        मंत्रशास्त्र कार्यशाळा 2</span></a></li>
                                <li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#tab_karya3"><i
                                    className="feather icon-feather-compass icon-extra-medium text-dark-gray"></i><span>श्री
                                        मंत्रशास्त्र कार्यशाळा 3</span></a></li>
                            </ul>
                            {/* end tab navigation */}
                        </div>
                        <div className="col-xl-9 col-lg-8 col-md-12">
                            <div className="tab-content">
                                {/* start tab content */}
                                <div className="tab-pane fade in active show" id="tab_karya1">
                                    <div className="row align-items-center">
                                        <div className="col-md-6 offset-xl-1 sm-mb-30px">
                                            <img src="assets/images/events/level1/level1_1.jpeg" alt=""
                                                className="border-radius-6px w-100" />
                                        </div>
                                        <div className="col-xl-4 col-md-6 offset-xl-1 text-center text-md-start">
                                            <h5 className="alt-font text-dark-gray mb-20px fw-500 ls-minus-1px"><span
                                                className="fw-600">श्री मंत्रशास्त्र कार्यशाळा </span> जीवन बदलणारा मार्ग</h5>
                                            <p> श्री मंत्रशास्त्र कार्यशाळा ही मंत्र, तंत्र आणि यंत्र यांचे गूढ ज्ञान उलगडणारी एक विशेष साधना आहे. या कार्यशाळेत मंत्रांचे महत्व, त्यांचा प्रभाव आणि योग्य प्रयोग शिकवले जातात. अध्यात्मिक शांती, मानसिक स्थैर्य आणि यश मिळवण्यासाठी ही कार्यशाळा उपयुक्त ठरते. प्रत्येकाला त्यांच्या समस्यांवर उपाय शोधण्यासाठी मार्गदर्शन दिले जाते. जीवनाच्या प्रत्येक टप्प्यावर सकारात्मक बदल अनुभवण्यासाठी ही संधी लाभदायक आहे.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* end tab content */}
                                {/* start tab content */}
                                <div className="tab-pane fade in" id="tab_karya2">
                                    <div className="row align-items-center">
                                        <div className="col-md-6 offset-xl-1 sm-mb-30px">
                                            <img src="assets/images/events/level1/level1_2.jpeg" alt=""
                                                className="border-radius-6px w-100" />
                                        </div>
                                        <div className="col-xl-4 col-md-6 offset-xl-1 text-center text-md-start">
                                            <h5 className="alt-font text-dark-gray mb-20px fw-500 ls-minus-1px"><span
                                                className="fw-600">श्री मंत्रशास्त्र कार्यशाळा </span> जीवन बदलणारा मार्ग</h5>
                                            <p> श्री मंत्रशास्त्र कार्यशाळा ही मंत्र, तंत्र आणि यंत्र यांचे गूढ ज्ञान उलगडणारी एक विशेष साधना आहे. या कार्यशाळेत मंत्रांचे महत्व, त्यांचा प्रभाव आणि योग्य प्रयोग शिकवले जातात. अध्यात्मिक शांती, मानसिक स्थैर्य आणि यश मिळवण्यासाठी ही कार्यशाळा उपयुक्त ठरते. प्रत्येकाला त्यांच्या समस्यांवर उपाय शोधण्यासाठी मार्गदर्शन दिले जाते. जीवनाच्या प्रत्येक टप्प्यावर सकारात्मक बदल अनुभवण्यासाठी ही संधी लाभदायक आहे.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* end tab content */}
                                {/* start tab content */}
                                <div className="tab-pane fade in" id="tab_karya3">
                                    <div className="row align-items-center">
                                        <div className="col-md-6 offset-xl-1 sm-mb-30px">
                                            <img src="assets/images/events/level1/level3_1.jpeg" alt=""
                                                className="border-radius-6px w-100" />
                                        </div>
                                        <div className="col-xl-4 col-md-6 offset-xl-1 text-center text-md-start">
                                            <h5 className="alt-font text-dark-gray mb-20px fw-500 ls-minus-1px"><span
                                                className="fw-600">श्री मंत्रशास्त्र कार्यशाळा </span> जीवन बदलणारा मार्ग</h5>
                                            <p> श्री मंत्रशास्त्र कार्यशाळा ही मंत्र, तंत्र आणि यंत्र यांचे गूढ ज्ञान उलगडणारी एक विशेष साधना आहे. या कार्यशाळेत मंत्रांचे महत्व, त्यांचा प्रभाव आणि योग्य प्रयोग शिकवले जातात. अध्यात्मिक शांती, मानसिक स्थैर्य आणि यश मिळवण्यासाठी ही कार्यशाळा उपयुक्त ठरते. प्रत्येकाला त्यांच्या समस्यांवर उपाय शोधण्यासाठी मार्गदर्शन दिले जाते. जीवनाच्या प्रत्येक टप्प्यावर सकारात्मक बदल अनुभवण्यासाठी ही संधी लाभदायक आहे.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* end tab content */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-solitude-blue">
                <div className="container">
                    <div className="row justify-content-center mb-4">
                        <div className="col-xl-7 col-lg-9 col-md-10 text-center">
                            <h3 className="alt-font text-dark-gray fw-600 ls-minus-1px"
                                data-anime-4>
                                Our various sadhanas</h3>
                        </div>
                    </div>
                    <div className="row align-items-center" data-anime-5>
                        <div className="col-xl-3 col-lg-4 col-md-12 tab-style-05 md-mb-30px sm-mb-20px">
                            {/* start tab navigation */}
                            <ul className="nav nav-tabs justify-content-center border-0 text-left fw-500 fs-8 alt-font">
                                <li className="nav-item"><a data-bs-toggle="tab" href="#tab_shiv"
                                    className="nav-link d-flex align-items-center active"><i
                                        className="feather icon-feather-compass icon-extra-medium text-dark-gray active"></i><span>महाशिवरात्री
                                            जागरण साधना</span></a></li>
                                <li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#tab_ram"><i
                                    className="feather icon-feather-compass icon-extra-medium text-dark-gray"></i><span>श्री
                                        राम ध्यान साधनेसहित रामरक्षा कार्यशाळा</span></a></li>
                                <li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#tab_hanuman"><i
                                    className="feather icon-feather-compass icon-extra-medium text-dark-gray"></i><span>श्री
                                        हनुमान सिद्धि साधना कार्यशाळा</span></a></li>
                                <li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#tab_navratri"><i
                                    className="feather icon-feather-compass icon-extra-medium text-dark-gray"></i><span>देवी
                                        नवरात्री साधना </span></a></li>
                            </ul>
                            {/* end tab navigation */}
                        </div>
                        <div className="col-xl-9 col-lg-8 col-md-12">
                            <div className="tab-content">
                                {/* start tab content */}
                                <div className="tab-pane fade in active show" id="tab_shiv">
                                    <div className="row align-items-center">
                                        <div className="col-md-6 offset-xl-1 sm-mb-30px">
                                            <img src="assets/images/events/shiratri2023/shivratri2023_1.jpeg" alt=""
                                                className="border-radius-6px w-100" />
                                        </div>
                                        <div className="col-xl-4 col-md-6 offset-xl-1 text-center text-md-start">
                                            <h5 className="alt-font text-dark-gray mb-20px fw-500 ls-minus-1px"><span
                                                className="fw-600">महाशिवरात्री जागरण साधना - </span> शिवत्वाचे जागरण</h5>
                                            <p> महाशिवरात्री जागरण साधना ही शिवत्वाशी एकरूप होण्यासाठीची पवित्र रात्र आहे. या साधनेत ओंकार, महामृत्युंजय मंत्र व जप यांचा समावेश असतो. साधनेमुळे आत्मिक ऊर्जा वाढते व मनःशांती मिळते. शिवाच्या कृपेने जीवनातील अडथळे दूर होतात आणि सकारात्मकता निर्माण होते. ही रात्र आत्मविश्लेषणासाठी आणि जीवनाला नवीन उर्जा देण्यासाठी अत्यंत महत्त्वाची आहे.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* end tab content */}
                                {/* start tab content */}
                                <div className="tab-pane fade in" id="tab_ram">
                                    <div className="row align-items-center">
                                        <div className="col-md-6 offset-xl-1 sm-mb-30px">
                                            <img src="assets/images/sadhana/ram-raksha.webp" alt=""
                                                className="border-radius-6px w-100" />
                                        </div>
                                        <div className="col-xl-4 col-md-6 offset-xl-1 text-center text-md-start">
                                            <h5 className="alt-font text-dark-gray mb-20px fw-500 ls-minus-1px"><span
                                                className="fw-600">राम ध्यान साधनेसहित रामरक्षा कार्यशाळा -  </span> रामनामाचा आशीर्वाद (Online)</h5>
                                            <p> राम ध्यान साधनेसहित रामरक्षा कार्यशाळा ही अध्यात्मिक उन्नतीसाठी एक अद्वितीय संधी आहे. या कार्यशाळेत रामनाम जप, रामरक्षेचे महत्त्व आणि ध्यानसाधनेचे मार्गदर्शन दिले जाते. जीवनातील अडचणींवर मात करण्यासाठी रामरक्षेची शक्ती अनुभवता येते. मनःशांती, आत्मबल, आणि सकारात्मकता यासाठी ही साधना उपयुक्त ठरते. रामचंद्रांच्या कृपेने जीवन अधिक सुगम आणि आनंदी बनवण्याचा मार्ग येथे शिकता येतो.</p>

                                        </div>
                                    </div>
                                </div>
                                {/* end tab content */}
                                {/* start tab content */}
                                <div className="tab-pane fade in" id="tab_hanuman">
                                    <div className="row align-items-center">
                                        <div className="col-md-6 offset-xl-1 sm-mb-30px">
                                            <img src="assets/images/sadhana/hanuman.webp" alt=""
                                                className="border-radius-6px w-100" />
                                        </div>
                                        <div className="col-xl-4 col-md-6 offset-xl-1 text-center text-md-start">
                                            <h5 className="alt-font text-dark-gray mb-20px fw-500 ls-minus-1px"><span
                                                className="fw-600">हनुमान सिद्धि साधना कार्यशाळा - </span> पराक्रम व आत्मबलाचा जागर</h5>
                                            <p> हनुमान सिद्धि साधना कार्यशाळा तुमच्यातील अदम्य शक्ती आणि आत्मबल जागवण्यासाठी एक अद्वितीय साधना आहे. या कार्यशाळेत हनुमानजींच्या मंत्रांचा जप, ध्यान आणि ऊर्जा साधना शिकवली जाते. संकटांवर मात करण्यासाठी आणि मनोबल वाढवण्यासाठी ही साधना उपयुक्त ठरते. हनुमानजींच्या कृपेने शारीरिक, मानसिक, आणि आध्यात्मिक उन्नती अनुभवता येते. जीवनाला नवीन दिशा आणि प्रेरणा देणारी ही कार्यशाळा आहे.</p>

                                        </div>
                                    </div>
                                </div>
                                {/* end tab content */}
                                {/* start tab content */}
                                <div className="tab-pane fade in" id="tab_navratri">
                                    <div className="row align-items-center">
                                        <div className="col-md-6 offset-xl-1 sm-mb-30px">
                                            <img src="assets/images/techturn/services/stress_analysis.jpg" alt=""
                                                className="border-radius-6px w-100" />
                                        </div>
                                        <div className="col-xl-4 col-md-6 offset-xl-1 text-center text-md-start">
                                            <h5 className="alt-font text-dark-gray mb-20px fw-500 ls-minus-1px"><span
                                                className="fw-600">ऑनलाइन नवरात्री साधना -  </span> देवीची कृपा आपल्या घरी</h5>
                                            <p> ऑनलाइन नवरात्री साधना तुम्हाला आपल्या घरी बसून देवीची उपासना करण्याची संधी देते. या साधनेत नवरात्र उत्सवाचे महत्व, मंत्रजप, ध्यान, आणि पूजेचे मार्गदर्शन दिले जाते. जीवनातील अडथळे दूर करण्यासाठी आणि सकारात्मक ऊर्जा मिळवण्यासाठी ही साधना उपयुक्त आहे. देवीच्या कृपेने मनःशांती, आत्मबल, आणि यश प्राप्त होते. नवरात्री साधनेत सहभागी होऊन देवीची कृपा अनुभवण्याचा हा सोपा आणि पवित्र मार्ग आहे.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-solitude-blue">
                <div className="container">
                    <div className="row justify-content-center mb-4">
                        <div className="col-xl-7 col-lg-9 col-md-10 text-center">
                            <h3 className="alt-font text-dark-gray fw-600 ls-minus-1px"
                                data-anime-4>
                                and more...</h3>
                        </div>
                    </div>
                    <div className="row align-items-center" data-anime-5>
                        <div className="col-xl-3 col-lg-4 col-md-12 tab-style-05 md-mb-30px sm-mb-20px">
                            {/* start tab navigation */}
                            <ul className="nav nav-tabs justify-content-center border-0 text-left fw-500 fs-8 alt-font">
                                <li className="nav-item"><a data-bs-toggle="tab" href="#tab_ganesh"
                                    className="nav-link d-flex align-items-center active"><i
                                        className="feather icon-feather-briefcase icon-extra-medium text-dark-gray"></i><span>श्री
                                            गणेश शक्ती साधना.</span></a></li>
                                <li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#tab_das"><i
                                    className="feather icon-feather-edit icon-extra-medium text-dark-gray"></i><span>श्री
                                        दस महाविद्या साधना</span></a></li>
                                <li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#tab_vishnu"><i
                                    className="feather icon-feather-compass icon-extra-medium text-dark-gray"></i><span>श्री
                                        विष्णू याग साधना</span></a></li>
                                <li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#tab_yantra"><i
                                    className="feather icon-feather-edit icon-extra-medium text-dark-gray"></i><span>श्री
                                        यंत्रशास्त्र कार्यशाळा</span></a></li>
                            </ul>
                            {/* end tab navigation */}
                        </div>
                        <div className="col-xl-9 col-lg-8 col-md-12">
                            <div className="tab-content">
                                {/* start tab content */}
                                <div className="tab-pane fade in active show" id="tab_ganesh">
                                    <div className="row align-items-center">
                                        <div className="col-md-6 offset-xl-1 sm-mb-30px">
                                            <img src="assets/images/sadhana/ganesh.webp" alt=""
                                                className="border-radius-6px w-100" />
                                        </div>
                                        <div className="col-xl-4 col-md-6 offset-xl-1 text-center text-md-start">
                                            <h5 className="alt-font text-dark-gray mb-20px fw-500 ls-minus-1px"><span
                                                className="fw-600">ऑनलाइन गणेश शक्ती साधना  - </span> विघ्नहर्त्याचे आशीर्वाद आपल्या घरी</h5>
                                            <p>ऑनलाइन गणेश शक्ती साधना तुम्हाला आपल्या घरी बसून गणपती बाप्पाची साधना करण्याची सुवर्णसंधी देते. या साधनेत गणेश मंत्र, ध्यान, आणि शक्तिप्राप्तीचे मार्गदर्शन दिले जाते. विघ्न दूर करून यश, शांती, आणि समृद्धी मिळवण्यासाठी ही साधना अत्यंत प्रभावी आहे. गणेश साधनेच्या माध्यमातून तुमच्या जीवनातील सकारात्मक बदल अनुभवता येतील. विघ्नहर्त्याच्या कृपेने आत्मविश्वास आणि आध्यात्मिक प्रगती साधण्याचा हा अद्वितीय मार्ग आहे.</p>
                                        </div>
                                    </div>
                                </div>
                                {/* end tab content */}
                                {/* start tab content */}
                                <div className="tab-pane fade in" id="tab_das">
                                    <div className="row align-items-center">
                                        {/* <div className="col-md-6 offset-xl-1 sm-mb-30px">
                                            <img src="assets/images/sadhana/ram-raksha.webp" alt=""
                                                className="border-radius-6px w-100" />
                                        </div>
                                        <div className="col-xl-4 col-md-6 offset-xl-1 text-center text-md-start">
                                            <h5 className="alt-font text-dark-gray mb-20px fw-500 ls-minus-1px"><span
                                                className="fw-600">राम ध्यान साधनेसहित रामरक्षा कार्यशाळा -  </span> रामनामाचा आशीर्वाद (Online)</h5>
                                            <p> राम ध्यान साधनेसहित रामरक्षा कार्यशाळा ही अध्यात्मिक उन्नतीसाठी एक अद्वितीय संधी आहे. या कार्यशाळेत रामनाम जप, रामरक्षेचे महत्त्व आणि ध्यानसाधनेचे मार्गदर्शन दिले जाते. जीवनातील अडचणींवर मात करण्यासाठी रामरक्षेची शक्ती अनुभवता येते. मनःशांती, आत्मबल, आणि सकारात्मकता यासाठी ही साधना उपयुक्त ठरते. रामचंद्रांच्या कृपेने जीवन अधिक सुगम आणि आनंदी बनवण्याचा मार्ग येथे शिकता येतो.</p>

                                        </div> */}
                                    </div>
                                </div>
                                {/* end tab content */}
                                {/* start tab content */}
                                <div className="tab-pane fade in" id="tab_vishnu">
                                    <div className="row align-items-center">
                                        {/* <div className="col-md-6 offset-xl-1 sm-mb-30px">
                                            <img src="assets/images/sadhana/hanuman.webp" alt=""
                                                className="border-radius-6px w-100" />
                                        </div>
                                        <div className="col-xl-4 col-md-6 offset-xl-1 text-center text-md-start">
                                            <h5 className="alt-font text-dark-gray mb-20px fw-500 ls-minus-1px"><span
                                                className="fw-600">हनुमान सिद्धि साधना कार्यशाळा - </span> पराक्रम व आत्मबलाचा जागर</h5>
                                            <p> हनुमान सिद्धि साधना कार्यशाळा तुमच्यातील अदम्य शक्ती आणि आत्मबल जागवण्यासाठी एक अद्वितीय साधना आहे. या कार्यशाळेत हनुमानजींच्या मंत्रांचा जप, ध्यान आणि ऊर्जा साधना शिकवली जाते. संकटांवर मात करण्यासाठी आणि मनोबल वाढवण्यासाठी ही साधना उपयुक्त ठरते. हनुमानजींच्या कृपेने शारीरिक, मानसिक, आणि आध्यात्मिक उन्नती अनुभवता येते. जीवनाला नवीन दिशा आणि प्रेरणा देणारी ही कार्यशाळा आहे.</p>

                                        </div> */}
                                    </div>
                                </div>
                                {/* end tab content */}
                                {/* start tab content */}
                                <div className="tab-pane fade in" id="tab_yantra">
                                    <div className="row align-items-center">
                                        {/* <div className="col-md-6 offset-xl-1 sm-mb-30px">
                                            <img src="assets/images/techturn/services/stress_analysis.jpg" alt=""
                                                className="border-radius-6px w-100" />
                                        </div>
                                        <div className="col-xl-4 col-md-6 offset-xl-1 text-center text-md-start">
                                            <h5 className="alt-font text-dark-gray mb-20px fw-500 ls-minus-1px"><span
                                                className="fw-600">ऑनलाइन नवरात्री साधना -  </span> देवीची कृपा आपल्या घरी</h5>
                                            <p> ऑनलाइन नवरात्री साधना तुम्हाला आपल्या घरी बसून देवीची उपासना करण्याची संधी देते. या साधनेत नवरात्र उत्सवाचे महत्व, मंत्रजप, ध्यान, आणि पूजेचे मार्गदर्शन दिले जाते. जीवनातील अडथळे दूर करण्यासाठी आणि सकारात्मक ऊर्जा मिळवण्यासाठी ही साधना उपयुक्त आहे. देवीच्या कृपेने मनःशांती, आत्मबल, आणि यश प्राप्त होते. नवरात्री साधनेत सहभागी होऊन देवीची कृपा अनुभवण्याचा हा सोपा आणि पवित्र मार्ग आहे.</p>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-solitude-blue">
                <div className="container">
                    <div className="row justify-content-center mb-4">
                        <div className="col-xl-7 col-lg-9 col-md-10 text-center">
                            <h3 className="alt-font text-dark-gray fw-600 ls-minus-1px"
                                data-anime-4>
                                and more...</h3>
                        </div>
                    </div>
                    <div className="row align-items-center" data-anime-5>
                        <div className="col-xl-3 col-lg-4 col-md-12 tab-style-05 md-mb-30px sm-mb-20px">
                            {/* start tab navigation */}
                            <ul className="nav nav-tabs justify-content-center border-0 text-left fw-500 fs-8 alt-font">
                                <li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#tab_karya2"><i
                                    className="feather icon-feather-compass icon-extra-medium text-dark-gray"></i><span>श्री
                                        सूक्त साधना प्रयोग</span></a></li>
                                <li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#tab_karya3"><i
                                    className="feather icon-feather-compass icon-extra-medium text-dark-gray"></i><span>श्री
                                        वैभव लक्ष्मी श्रीयंत्र साधना शिबिर</span></a></li>
                                <li className="nav-item"><a data-bs-toggle="tab" href="#tab_karya1"
                                    className="nav-link d-flex align-items-center active"><i
                                        className="feather icon-feather-compass icon-extra-medium text-dark-gray"></i><span>श्री
                                            शाबरी विद्या कार्यशाळा</span></a></li>
                                <li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#tab_karya3"><i
                                    className="feather icon-feather-compass icon-extra-medium text-dark-gray"></i><span>श्री
                                        मुद्रा कार्यशाळा</span></a></li>
                            </ul>
                            {/* end tab navigation */}
                        </div>
                        <div className="col-xl-9 col-lg-8 col-md-12">
                            <div className="tab-content">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-solitude-gray">
                <div className="container">
                    <div className="row justify-content-center mb-4">
                        <div className="col-xl-7 col-lg-9 col-md-10 text-center">
                            <h3 className="alt-font text-dark-gray fw-600 ls-minus-1px"
                                data-anime-4>
                                and more...</h3>
                        </div>
                    </div>
                    <div className="row align-items-center" data-anime-5>
                        <div className="col-xl-3 col-lg-4 col-md-12 tab-style-05 md-mb-30px sm-mb-20px">
                            {/* start tab navigation */}
                            <ul className="nav nav-tabs justify-content-center border-0 text-left fw-500 fs-8 alt-font">
                                <li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#tab_pitru"><i
                                    className="feather icon-feather-compass icon-extra-medium text-dark-gray"></i><span>पितृ
                                        दोष निवारण विधी</span></a></li>
                                <li className="nav-item"><a data-bs-toggle="tab" href="#tab_karya1"
                                    className="nav-link d-flex align-items-center active"><i
                                        className="feather icon-feather-briefcase icon-extra-medium text-dark-gray"></i><span>श्री
                                            विद्या (श्रीचक्र सिद्धि) तंत्र कार्यशाळा</span></a></li>
                                <li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#tab_karya2"><i
                                    className="feather icon-feather-edit icon-extra-medium text-dark-gray"></i><span>श्री
                                        खड्गमाला तंत्र कार्यशाळा</span></a></li>
                                <li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#tab_karya2"><i
                                    className="feather icon-feather-edit icon-extra-medium text-dark-gray"></i><span>श्री
                                        कुंडलिनी शक्ती कार्यशाळा</span></a></li>
                            </ul>
                            {/* end tab navigation */}
                        </div>
                        <div className="col-xl-9 col-lg-8 col-md-12">
                            <div className="tab-content">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-solitude-blue">
                <div className="container">
                    <div className="row justify-content-center mb-4">
                        <div className="col-xl-7 col-lg-9 col-md-10 text-center">
                            <h3 className="alt-font text-dark-gray fw-600 ls-minus-1px"
                                data-anime-4>
                                and more...</h3>
                        </div>
                    </div>
                    <div className="row align-items-center" data-anime-5>
                        <div className="col-xl-3 col-lg-4 col-md-12 tab-style-05 md-mb-30px sm-mb-20px">
                            {/* start tab navigation */}
                            <ul className="nav nav-tabs justify-content-center border-0 text-left fw-500 fs-8 alt-font">
                                <li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#tab_karya2"><i
                                    className="feather icon-feather-edit icon-extra-medium text-dark-gray"></i><span>श्री
                                        गणेश ध्यान कार्यशाळा</span></a></li>
                                <li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#tab_karya2"><i
                                    className="feather icon-feather-edit icon-extra-medium text-dark-gray"></i><span>श्री
                                        गायत्री ध्यान कार्यशाळा</span></a></li>
                                <li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#tab_karya2"><i
                                    className="feather icon-feather-edit icon-extra-medium text-dark-gray"></i><span>श्री
                                        शिव ध्यान कार्यशाळा</span></a></li>
                                <li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#tab_karya2"><i
                                    className="feather icon-feather-edit icon-extra-medium text-dark-gray"></i><span>श्री
                                        शक्ती ध्यान कार्यशाळा</span></a></li>
                                <li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#tab_karya2"><i
                                    className="feather icon-feather-edit icon-extra-medium text-dark-gray"></i><span>श्री
                                        चक्र ध्यान कार्यशाळा</span></a></li>
                                <li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#tab_karya2"><i
                                    className="feather icon-feather-edit icon-extra-medium text-dark-gray"></i><span>पातंजल
                                        योग ध्यान कार्यशाळा</span></a></li>
                            </ul>
                            {/* end tab navigation */}
                        </div>
                        <div className="col-xl-9 col-lg-8 col-md-12">
                            <div className="tab-content">
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-solitude-red">
                <div className="container">
                    <div className="row justify-content-center mb-4">
                        <div className="col-xl-7 col-lg-9 col-md-10 text-center">
                            <h3 className="alt-font text-dark-gray fw-600 ls-minus-1px"
                                data-anime-4>
                                and more...</h3>
                        </div>
                    </div>
                    <div className="row align-items-center" data-anime-5>
                        <div className="col-xl-3 col-lg-4 col-md-12 tab-style-05 md-mb-30px sm-mb-20px">
                            {/* start tab navigation */}
                            <ul className="nav nav-tabs justify-content-center border-0 text-left fw-500 fs-8 alt-font">
                                <li className="nav-item"><a data-bs-toggle="tab" href="#tab_niwasi"
                                    className="nav-link d-flex align-items-center active"><i
                                        className="feather icon-feather-briefcase icon-extra-medium text-dark-gray"></i><span>निवासी
                                            साधना शिबिर</span></a></li>
                                <li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#tab_guru"><i
                                    className="feather icon-feather-edit icon-extra-medium text-dark-gray"></i><span>गुरुपौर्णिमा
                                        उत्सव आणि सत्संग साधना सोहळा</span></a></li>

                                <li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#tab_karya2"><i
                                    className="feather icon-feather-edit icon-extra-medium text-dark-gray"></i><span>श्री
                                        गुरु सत्संग</span></a></li>
                                <li className="nav-item"><a className="nav-link" data-bs-toggle="tab" href="#tab_karya3"><i
                                    className="feather icon-feather-compass icon-extra-medium text-dark-gray"></i><span>अमावस्या
                                        ध्यान साधना</span></a></li>

                                <li className="nav-item"><a data-bs-toggle="tab" href="#tab_karya1"
                                    className="nav-link d-flex align-items-center active"><i
                                        className="feather icon-feather-briefcase icon-extra-medium text-dark-gray"></i>
                                    <span>प्रसंगोपात</span></a>
                                </li>
                            </ul>
                            {/* end tab navigation */}
                        </div>
                        <div className="col-xl-9 col-lg-8 col-md-12">
                            <div className="tab-content">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}
const mapStateToProps = (state) => {
    return { data: state.dCandidate.list };
};

const mapActionToProps = {
    fetchData: actions.fetchallProperty
}

export default connect(mapStateToProps, mapActionToProps)(Sadhanas);