import React, { Fragment } from 'react';
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../actions/dCandidate";
import anime from 'animejs';
import axios from 'axios';

const About = (props) => {
    const [testoData, setTestData] = useState([]);
    const [aboutData, setAboutData] = useState([]);
    const [pageImage, setPageImage] = useState("");
    const [visionImage, setVisionImage] = useState("");
    useEffect(() => {
        props.fetchData();
        anime({
            targets: '[data-anime]', // Select elements with the data attribute
            el: "childs", translateY: [50, 0], opacity: [0,1], duration: 600, delay: 0, staggervalue: 300, easing: "easeOutQuad"
          });
        anime({
            targets: '[data-anime-1]', // Select elements with the data attribute
            translateY: [-50, 0], opacity: [0,1], duration: 600, delay: 0, staggervalue: 300, easing: "easeOutQuad"
        });          
        anime({
            targets: '[data-anime-2]', // Select elements with the data attribute
            el: "childs", translateY: [30, 0], opacity: [0,1], duration: 600, delay: 0, staggervalue: 300, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-3]', // Select elements with the data attribute
            el: "lines", translateY: [30, 0], opacity: [0,1], duration: 600, delay: 0, staggervalue: 300, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-4]', // Select elements with the data attribute
            el: "childs", translateY: [30, 0], opacity: [0,1], duration: 600, delay: 0, staggervalue: 150, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-5]', // Select elements with the data attribute
            translateY: [0, 0], opacity: [0,1], duration: 1200, delay: 0, staggervalue: 150, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-6]', // Select elements with the data attribute
            translateY: [0,0], opacity: [0,1], duration: 800, delay: 100, staggervalue: 300, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-7]', // Select elements with the data attribute
            translateY: [0,0], opacity: [0,1], duration: 800, delay: 150, staggervalue: 300, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-8]', // Select elements with the data attribute
            translateY: [0, 0], opacity: [0,1], duration: 1200, delay: 100, staggervalue: 150, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-9]', // Select elements with the data attribute
            e1: "childs", translateX: [50, 0], opacity: [0,1], duration: 1200, delay: 0, staggervalue: 150, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-10]', // Select elements with the data attribute
            translateY: [0, 0], opacity: [0,1], duration: 1200, delay: 0, staggervalue: 150, easing: "easeOutQuad"
        });
    }, [])
   
    // useEffect(() => {
    //     const makeRequest = async () => {
    //         let jsn = await props.data;
    //         if (jsn != null) {
    //             if(jsn.Testonomials != null){
    //                 let parsedArray = JSON.parse(jsn.Testonomials);
    //                 setTestData(parsedArray);
    //             }
    //             if(jsn.About != null){
    //                 if(JSON.parse(jsn.About).length){
    //                     setAboutData(JSON.parse(jsn.About)[0]);
    //                     setPageImage(process.env.REACT_APP_API_HOST +"/About/"+aboutData.PageImage);
    //                     setVisionImage(process.env.REACT_APP_API_HOST +"/About/"+aboutData.VisionImage);
    //                 }
    //             }
    //         }
    //     }
    //     makeRequest();
    // }, [props.data]);

    function CheckImage(path) {
        axios.get(path).then(() => { return true; }) .catch(() => { return false; });
      }

    function remove_n_words(words, n) {
        if(words != null){
            return n === 0 ? words : words.split(" ").slice(0, -n).join(" ");
        }
    }
    
    function getLastNWords(str, n) {
        if(str != null){
            const words = str.split(' ');
            if (words.length < n) {
              return str; // Return the original string if there are fewer than 2 words
            }
            return words.slice(-n).join(' ');    
        }
      }

      return (
        <Fragment>
            {/* start page title */}
            <section className="top-space-margin page-title-big-typography border-radius-6px lg-border-radius-0px p-0" data-parallax-background-ratio="0.5" style={{backgroundImage: 'url(assets/images/others/about_us.jpeg)'}}>
                <div className="opacity-extra-medium bg-blue-whale"></div>
                <div className="container">
                    <div className="row align-items-center justify-content-center small-screen">
                        <div className="col-lg-8 position-relative text-center page-title-extra-large" data-anime>
                            <h1 className="m-auto text-white text-shadow-double-large fw-600 ls-minus-2px">About us</h1>
                        </div>
                        <div className="down-section text-center" data-anime-1>
                            <a href="#down-section" className="section-link">
                                <div className="text-white">
                                    <i className="feather icon-feather-chevron-down icon-very-medium"></i>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            {/* end page title */}
            {/* start section */}
            <section id="down-section" className="border-bottom border-color-extra-medium-gray">
                <div className="container overlap-gap-section"> 
                    <div className="row align-items-end justify-content-center mb-5 md-mb-40px text-center text-md-start">
                        <div className="col-xl-5 col-lg-6 col-md-10 md-mb-20px text-center text-lg-start" data-anime-2>
                            <span className="ps-25px pe-25px mb-20px text-uppercase text-base-color fs-14 lh-42px fw-700 border-radius-100px bg-gradient-very-light-gray-transparent d-inline-block">About us</span>
                            <h3 className="text-dark-gray fw-700 mb-0 ls-minus-1px">Spreading knowledge of Akshar's Urja <br></br> & It's Marm Yog</h3>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-10 offset-xl-1 text-center text-lg-start last-paragraph-no-margin">
                            <p className="w-90 xl-w-100" data-anime-3><span><h6>Gurushree Srimant Jayant Zarekar Ji</h6>  is a Psychologist. He is a hypnotherapist. He is also an NLP practitioner. He is a counsellor. He is a horoscope reader in traditional as well as Krishnamurti Padhdati. He is deeply passionate about understanding and harnessing the potential of fellow human beings. He is a spiritual guide. He has passed his B.A. exam. With Psychology. He has well over 37 years of robust experience in practising various mantras, tantras and yantras. He is guiding and helping the people to overcome their problems by using the mantra power. He honestly feels that in today’s situation, the human being can survive only through the creating right frequencies of the mantras. He is blessed by and always in close touch with great (but unknown) yogis, gurus, saints in nowadays. He has received the Diksha by various gurus, like Vamnarao Gulwani Maharaj, Sri Nikhileshwaranand Maharaj, Kriya yoga, Kundalini Jagruti, Dhyan Yoga, Mantra Yoga etc.</span></p>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-lg-4 row-cols-md-2 row-cols-sm-2 justify-content-center" data-anime-4>
                        {/* start features box item */}
                        <div className="col custom-icon-with-text-style-02">
                            <div className="feature-box p-6 last-paragraph-no-margin overflow-hidden md-mb-20px">
                                <div className="feature-box-icon">
                                    <img className="custom-image-icon mb-20px" src="assets/images/trusted.webp" alt="" />
                                </div>
                                <div className="feature-box-content">
                                    <span className="d-block fs-19 fw-700 text-dark-gray mb-5px">Trusted by Devotees</span>
                                    <p>We are trusted by 25000+ sadhaks.</p>
                                </div>
                            </div>
                        </div>
                        {/* end features box item */}
                        {/* start features box item */}
                        <div className="col custom-icon-with-text-style-02">
                            <div className="feature-box p-6 last-paragraph-no-margin overflow-hidden md-mb-20px">
                                <div className="feature-box-icon">
                                    <img className="custom-image-icon mb-20px" src="assets/images/professional.webp" alt="" />
                                </div>
                                <div className="feature-box-content">
                                    <span className="d-block fs-19 fw-700 text-dark-gray mb-5px">Professional</span>
                                    <p>40+ years of deep learning and solutions to people.</p>
                                </div>
                            </div>
                        </div>
                        {/* end features box item */}
                        {/* start features box item */}
                        <div className="col custom-icon-with-text-style-02">
                            <div className="feature-box p-6 last-paragraph-no-margin overflow-hidden xs-mb-20px">
                                <div className="feature-box-icon">
                                    <img className="custom-image-icon mb-20px" src="assets/images/award.webp" alt="" />
                                </div>
                                <div className="feature-box-content">
                                    <span className="d-block fs-19 fw-700 text-dark-gray mb-5px">Award winning</span>
                                    <p>We believe in challenges so we have made challenges.</p>
                                </div>
                            </div>
                        </div>
                        {/* end features box item */}
                        {/* start features box item */}
                        <div className="col custom-icon-with-text-style-02">
                            <div className="feature-box p-6 last-paragraph-no-margin overflow-hidden">
                                <div className="feature-box-icon">
                                    <img className="custom-image-icon mb-20px" src="assets/images/help.webp" alt="" />
                                </div>
                                <div className="feature-box-content">
                                    <span className="d-block fs-19 fw-700 text-dark-gray mb-5px">Help any time</span>
                                    <p>We never fail for support for your issues anywhere.</p>
                                </div>
                            </div>
                        </div>
                        {/* end features box item */}
                    </div>
                </div>
            </section>
            {/* end section */}
            {/* start section */} 
            <section className="py-0 sm-pt-50px" data-anime-5>
                <div className="container overlap-section">
                    <div className="row justify-content-center g-0">
                        <div className="col-auto text-center last-paragraph-no-margin icon-with-text-style-08 pt-20px pb-20px ps-8 pe-8 md-ps-30px md-pe-30px bg-white border border-color-extra-medium-gray box-shadow-medium-bottom border-radius-100px xs-border-radius-10px">
                            <div className="feature-box feature-box-left-icon-middle overflow-hidden">
                                <div className="feature-box-icon me-10px">
                                    <i className="bi bi-chat-text icon-extra-medium text-base-color"></i>
                                </div>
                                <div className="feature-box-content last-paragraph-no-margin text-dark-gray text-uppercase fs-15 fw-700 ls-05px">
                                <a href={"tel:+919922371107"} className="text-base-color text-decoration-line-bottom-medium border-1">Contact for more details </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end section */}
            {/* start section */}
            <section className="position-relative overflow-hidden">
                <div className="container">
                    <div className="row justify-content-center align-items-center mb-3">
                        <div className="col-xl-5 col-lg-6 md-mb-14 sm-mb-18 xs-mb-23 position-relative" data-anime-6>
                            <div className="w-75 sm-w-80" data-animation-delay="200" data-shadow-animation="true" data-bottom-top="transform: translateY(50px)" data-top-bottom="transform: translateY(-50px)">
                                <img src="assets/images/self/600x710.jpeg" alt="" className="border-radius-6px w-100" />
                                <div className="position-absolute top-30px z-index-8 left-minus-90px lg-left-minus-25px sm-left-minus-0px lg-w-30">
                                    <img src="assets/images/logo/logo_small.png" alt="" />
                                </div>
                            </div>
                            <div className="w-55 overflow-hidden position-absolute right-15px xs-w-55 bottom-minus-50px" data-shadow-animation="true" data-animation-delay="100" data-bottom-top="transform: translateY(20px)" data-top-bottom="transform: translateY(-20px)">
                                <img src="assets/images/self/600x633.jpeg" alt="" className="border-radius-6px box-shadow-quadruple-large w-100" />
                            </div>
                        </div>
                        <div className="col-xl-5 offset-xl-1 col-lg-6 text-center text-lg-start" data-anime-7>
                            <div className="swiper position-relative magic-cursor" data-slider-options='{ "autoHeight": true, "loop": true, "allowTouchMove": true, "autoplay": { "delay": 4000, "disableOnInteraction": false }, "navigation": { "nextEl": ".swiper-button-next", "prevEl": ".swiper-button-prev" }, "effect": "slide" }'>
                                <div className="swiper-wrapper mb-10px">
                                    {/* start text slider item */}
                                    <div className="swiper-slide">
                                        <span className="ps-25px pe-25px mb-20px text-uppercase text-base-color fs-14 lh-42px fw-700 border-radius-100px bg-gradient-very-light-gray-transparent d-inline-block">Our Mission</span>
                                        <h3 className="text-dark-gray fw-700 ls-minus-1px mb-20px">Empowering spiritual growth through the sacred science of mantras.</h3>
                                        <p className="w-95 xl-w-100">The "Aumyog Mission" likely refers to a spiritual or religious initiative that focuses on the study, practice, and promotion of "Mantra Shastra." Mantra Shastra is a branch of Vedic science in Hinduism that deals with the use and recitation of mantras—sacred sounds, words, or phrases believed to have spiritual and mystical powers.</p>
                                    </div>
                                    {/* end text slider item */}
                                    {/* start text slider item */}
                                    <div className="swiper-slide">
                                        <span className="ps-25px pe-25px mb-20px text-uppercase text-base-color fs-14 lh-42px fw-700 border-radius-100px bg-gradient-very-light-gray-transparent d-inline-block">Company vision</span>
                                        <h3 className="text-dark-gray fw-700 ls-minus-1px mb-20px">Transform Your Mind, Body, and Spirit through Ancient Wisdom</h3>
                                        <p className="w-95 xl-w-100">"To illuminate the transformative power of Mantra Shastra, offering a comprehensive resource that empowers individuals to harness the ancient wisdom of mantras for spiritual growth, mental well-being, and personal transformation."</p>
                                    </div>
                                    {/* end text slider item */}
                                </div> 
                                <div className="d-flex justify-content-center justify-content-lg-start">
                                    {/* start slider navigation */}
                                    <div className="slider-one-slide-prev-1 swiper-button-prev slider-navigation-style-04 border border-color-extra-medium-gray bg-white"><i className="bi bi-arrow-left-short icon-very-medium text-dark-gray"></i></div>
                                    <div className="slider-one-slide-next-1 swiper-button-next slider-navigation-style-04 border border-color-extra-medium-gray bg-white"><i className="bi bi-arrow-right-short icon-very-medium text-dark-gray"></i></div>
                                    {/* end slider navigation */} 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img src="assets/images/others/demo-corporate-about-bg-01.webp" className="position-absolute bottom-minus-50px right-minus-50px z-index-minus-1" data-bottom-top="transform: rotate(0deg) translateY(0)" data-top-bottom="transform:rotate(-15deg) translateY(0)" alt=""/>
            </section>
            {/* end section */}
            {/* start section */}
            <section className="bg-gradient-quartz-white border-radius-6px"> 
                <div className="container">
                    <div className="row mt-7">
                        <div className="col-12">
                            <span className="text-dark-gray fs-24 fw-600 alt-font mb-25px d-block">Objective and Benefits</span>
                        </div>
                        <div className="col-xl-6">
                            <div className="row g-0 align-items-center mb-15px pb-15px border-bottom border-color-extra-medium-gray">
                                <div className="col">
                                    {/* start features box item */}
                                    <div className="feature-box feature-box-left-icon-middle last-paragraph-no-margin">
                                        <div className="feature-box-icon me-10px">
                                            <img src="assets/images/logo/logo_small.png" className="w-25px" alt="" />
                                        </div>
                                        <div className="feature-box-content">
                                            <span className="text-dark-gray">Understanding the basics of Mantras.</span>
                                        </div>
                                    </div>
                                    {/* end features box item */}
                                </div>
                                {/* <div className="col">GE-G612</div> */}
                            </div>
                            <div className="row g-0 align-items-center mb-15px pb-15px border-bottom border-color-extra-medium-gray">
                                <div className="col">
                                    {/* start features box item */}
                                    <div className="feature-box feature-box-left-icon-middle last-paragraph-no-margin">
                                        <div className="feature-box-icon me-10px">
                                            <img src="assets/images/logo/logo_small.png" className="w-25px" alt="" />
                                        </div>
                                        <div className="feature-box-content">
                                            <span className="text-dark-gray">Understanding the format and structure of Mantras,</span>
                                        </div>
                                    </div>
                                    {/* end features box item */}
                                </div>
                            </div>
                            <div className="row g-0 align-items-center mb-15px pb-15px border-bottom border-color-extra-medium-gray">
                                <div className="col">
                                    {/* start features box item */}
                                    <div className="feature-box feature-box-left-icon-middle last-paragraph-no-margin">
                                        <div className="feature-box-icon me-10px">
                                            <img src="assets/images/logo/logo_small.png" className="w-25px" alt="" />
                                        </div>
                                        <div className="feature-box-content">
                                            <span className="text-dark-gray">Unfolding the mystery behind the Mantra power,</span>
                                        </div>
                                    </div>
                                    {/* end features box item */}
                                </div>
                            </div>
                            <div className="row g-0 align-items-center mb-15px pb-15px border-bottom border-color-extra-medium-gray">
                                <div className="col">
                                    {/* start features box item */}
                                    <div className="feature-box feature-box-left-icon-middle last-paragraph-no-margin">
                                        <div className="feature-box-icon me-10px">
                                            <img src="assets/images/logo/logo_small.png" className="w-25px" alt="" />
                                        </div>
                                        <div className="feature-box-content">
                                            <span className="text-dark-gray">To provide the Highly powerful mantras & process for Dhan Laxmi (Money).</span>
                                        </div>
                                    </div>
                                    {/* end features box item */}
                                </div>
                            </div>
                            <div className="row g-0 align-items-center lg-mb-15px lg-pb-15px lg-border-bottom border-color-extra-medium-gray">
                                <div className="col">
                                    {/* start features box item */}
                                    <div className="feature-box feature-box-left-icon-middle last-paragraph-no-margin">
                                        <div className="feature-box-icon me-10px">
                                            <img src="assets/images/logo/logo_small.png" className="w-25px" alt="" />
                                        </div>
                                        <div className="feature-box-content">
                                            <span className="text-dark-gray">How to use Mantras for Dhan Laxmi (Money)?</span>
                                        </div>
                                    </div>
                                    {/* end features box item */}
                                </div>
                            </div>
                            <div className="row g-0 align-items-center mb-15px pb-15px border-bottom border-color-extra-medium-gray">
                                <div className="col">
                                    {/* start features box item */}
                                    <div className="feature-box feature-box-left-icon-middle last-paragraph-no-margin">
                                        <div className="feature-box-icon me-10px">
                                            <img src="assets/images/logo/logo_small.png" className="w-25px" alt="" />
                                        </div>
                                        <div className="feature-box-content">
                                            <span className="text-dark-gray">Difference between Mantra and Tantra.</span>
                                        </div>
                                    </div>
                                    {/* end features box item */}
                                </div>
                            </div>
                            <div className="row g-0 align-items-center mb-15px pb-15px border-bottom border-color-extra-medium-gray">
                                <div className="col">
                                    {/* start features box item */}
                                    <div className="feature-box feature-box-left-icon-middle last-paragraph-no-margin">
                                        <div className="feature-box-icon me-10px">
                                            <img src="assets/images/logo/logo_small.png" className="w-25px" alt="" />
                                        </div>
                                        <div className="feature-box-content">
                                            <span className="text-dark-gray">What is Yantra?</span>
                                        </div>
                                    </div>
                                    {/* end features box item */}
                                </div>
                            </div>
                            <div className="row g-0 align-items-center mb-15px pb-15px border-bottom border-color-extra-medium-gray">
                                <div className="col">
                                    {/* start features box item */}
                                    <div className="feature-box feature-box-left-icon-middle last-paragraph-no-margin">
                                        <div className="feature-box-icon me-10px">
                                            <img src="assets/images/logo/logo_small.png" className="w-25px" alt="" />
                                        </div>
                                        <div className="feature-box-content">
                                            <span className="text-dark-gray">Various Mantras for various (wishes) objectives, such as Job, Promotion, Money, Prosperity, Wealth.</span>
                                        </div>
                                    </div>
                                    {/* end features box item */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="row g-0 align-items-center mb-15px pb-15px border-bottom border-color-extra-medium-gray">
                                <div className="col">
                                    {/* start features box item */}
                                    <div className="feature-box feature-box-left-icon-middle last-paragraph-no-margin">
                                        <div className="feature-box-icon me-10px">
                                            <img src="assets/images/logo/logo_small.png" className="w-25px" alt="" />
                                        </div>
                                        <div className="feature-box-content">
                                            <span className="text-dark-gray">‘Sri Laxmi Nyas’, the powerful technique of internalizing the mantra.</span>
                                        </div>
                                    </div>
                                    {/* end features box item */}
                                </div>
                            </div>
                            <div className="row g-0 align-items-center">
                                <div className="col">
                                    {/* start features box item */}
                                    <div className="feature-box feature-box-left-icon-middle last-paragraph-no-margin">
                                        <div className="feature-box-icon me-10px">
                                            <img src="assets/images/logo/logo_small.png" className="w-25px" alt="" />
                                        </div>
                                        <div className="feature-box-content">
                                            <span className="text-dark-gray">A powerful & useful Beej (seed) Mantra Meditation technique to purify the Body (Bhav Shudhdikaran Vidhi).</span>
                                        </div>
                                    </div>
                                    {/* end features box item */}
                                </div>
                            </div>
                            <div className="row g-0 align-items-center">
                                <div className="col">
                                    {/* start features box item */}
                                    <div className="feature-box feature-box-left-icon-middle last-paragraph-no-margin">
                                        <div className="feature-box-icon me-10px">
                                            <img src="assets/images/logo/logo_small.png" className="w-25px" alt="" />
                                        </div>
                                        <div className="feature-box-content">
                                            <span className="text-dark-gray">A powerful & useful Beej (seed) Mantra Meditation technique to attract & attune with prosperity.</span>
                                        </div>
                                    </div>
                                    {/* end features box item */}
                                </div>
                            </div>
                            <div className="row g-0 align-items-center">
                                <div className="col">
                                    {/* start features box item */}
                                    <div className="feature-box feature-box-left-icon-middle last-paragraph-no-margin">
                                        <div className="feature-box-icon me-10px">
                                            <img src="assets/images/logo/logo_small.png" className="w-25px" alt="" />
                                        </div>
                                        <div className="feature-box-content">
                                            <span className="text-dark-gray">A powerful session to create an awareness of the relation between Mantra Shakti, & power of the Universe.</span>
                                        </div>
                                    </div>
                                    {/* end features box item */}
                                </div>
                            </div>
                            <div className="row g-0 align-items-center">
                                <div className="col">
                                    {/* start features box item */}
                                    <div className="feature-box feature-box-left-icon-middle last-paragraph-no-margin">
                                        <div className="feature-box-icon me-10px">
                                            <img src="assets/images/logo/logo_small.png" className="w-25px" alt="" />
                                        </div>
                                        <div className="feature-box-content">
                                            <span className="text-dark-gray">A powerful & useful Beej (seed) Mantra Meditation technique to purify the Body (Bhav Shudhdikaran Vidhi).</span>
                                        </div>
                                    </div>
                                    {/* end features box item */}
                                </div>
                            </div>
                            <div className="row g-0 align-items-center">
                                <div className="col">
                                    {/* start features box item */}
                                    <div className="feature-box feature-box-left-icon-middle last-paragraph-no-margin">
                                        <div className="feature-box-icon me-10px">
                                            <img src="assets/images/logo/logo_small.png" className="w-25px" alt="" />
                                        </div>
                                        <div className="feature-box-content">
                                            <span className="text-dark-gray">A powerful session on “how to stop unwanted results & how to get the results you want?”</span>
                                        </div>
                                    </div>
                                    {/* end features box item */}
                                </div>
                            </div>

                        </div>
                    </div> 
                </div>
            </section>
            {/* end section */}

        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return { data: state.dCandidate.list };
};

const mapActionToProps = {
    fetchData: actions.fetchallAbout
}

export default connect(mapStateToProps, mapActionToProps)(About);