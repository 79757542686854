import { ACTION_TYPES } from "../actions/dCandidate";
const intialState ={
    list:""
}

export const dCandidate = (state = intialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.FETCH_ALL:
            return {
                ...state,
                list:action.payload
            }                
        case ACTION_TYPES.FETCH_ABOUT:
            return {
                ...state,
                list:action.payload
            }
        case ACTION_TYPES.FETCH_PROPERTY:
            return {
                ...state,
                list:action.payload
            }    
        case ACTION_TYPES.FETCH_CONTACT:
            return {
                ...state,
                list:action.payload
            }    
        case ACTION_TYPES.FETCH_PROPERTY_DETAILS:
            return {
                ...state,
                list:action.payload
            }    
    
        default:
        return state;
    }
}