import React, { Fragment } from 'react';
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../actions/dCandidate";
import anime from 'animejs';
import axios from 'axios';
import { BrowserRouter, Route, Link, Routes } from 'react-router-dom';

const Contact = (props) => {
    const [pageData, setPageData] = useState([]);
    const [companyData, setCompanyData] = useState([]);
    const [pageImage, setPageImage] = useState("");

    useEffect(() => {
        props.fetchData();
        anime({
            targets: '[data-anime]', // Select elements with the data attribute
            el: "childs", translateY: [30, 0], opacity: [0, 1], duration: 400, delay: 0, staggervalue: 100, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-1]', // Select elements with the data attribute
            translateY: [-50, 0], opacity: [0, 1], duration: 600, delay: 0, staggervalue: 300, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-2]', // Select elements with the data attribute
            e1: "childs", translateY: [30, 0], opacity: [0, 1], duration: 800, delay: 0, staggervalue: 300, easing: "easeOutQuad"
        });
        anime({
            targets: '[data-anime-3]', // Select elements with the data attribute
            e1: "childs", translateX: [50, 0], opacity: [0, 1], duration: 600, delay: 0, staggervalue: 300, easing: "easeOutQuad"
        });
    }, [])

    // useEffect(() => {
    //     const makeRequest = async () => {
    //         let jsn = await props.data;
    //         if (jsn != null) {
    //             if (jsn.Contact != null) {
    //                 if (JSON.parse(jsn.Contact).length) {
    //                     setPageData(JSON.parse(jsn.Contact)[0]);
    //                     setPageImage(process.env.REACT_APP_API_HOST + "/Contact/" + pageData.PageImage);
    //                 }
    //                 if (JSON.parse(jsn.Company).length) {
    //                     setCompanyData(JSON.parse(jsn.Company)[0]);
    //                 }
    //             }
    //         }
    //     }
    //     makeRequest();
    // }, [props.data]);

    function CheckImage(path) {
        axios.get(path).then(() => { return true; }).catch(() => { return false; });
    }

    function remove_n_words(words, n) {
        if (words != null) {
            return n === 0 ? words : words.split(" ").slice(0, -n).join(" ");
        }
    }

    function getLastNWords(str, n) {
        if (str != null) {
            const words = str.split(' ');
            if (words.length < n) {
                return str; // Return the original string if there are fewer than 2 words
            }
            return words.slice(-n).join(' ');
        }
    }

    return (
        <Fragment>
        {/* start page title */}
        <section className="page-title-big-typography bg-dark-gray ipad-top-space-margin xs-py-0 cover-background background-position-center-top" style={{backgroundImage: "url(assets/images/others/about_us.jpeg)"}}>
            <div className="opacity-extra-medium bg-gradient-black-green"></div>
            <div className="container">
                <div className="row align-items-center justify-content-center small-screen">
                    <div className="col-xl-6 col-lg-7 col-sm-8 position-relative text-center page-title-extra-small" data-anime>
                        <div><h1 className="text-uppercase mb-15px alt-font text-white opacity-6 fw-500 ls-2px" data-fancy-text='{ "opacity": [0, 1], "translateY": [50, 0], "filter": ["blur(20px)", "blur(0px)"], "string": ["Contact us"], "duration": 400, "delay": 0, "speed": 50, "easing": "easeOutQuad" }'>Contact Us</h1></div>
                        <h2 className="m-auto text-white alt-font text-shadow-double-large fw-700 w-90 xl-w-100" data-fancy-text='{ "opacity": [0, 1], "translateY": [50, 0], "filter": ["blur(20px)", "blur(0px)"], "string": ["Let&apos;s discuss your problems now"], "duration": 400, "delay": 0, "speed": 50, "easing": "easeOutQuad" }'>Let's discuss your problems now</h2>
                    </div>
                    <div className="down-section text-center" data-anime-1>
                        <a href="#down-section" className="section-link">
                            <div className="text-white">
                                <i className="line-icon-Down icon-medium"></i>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
        {/* end page title */}  
        {/* start section */}
        <section id="down-section" className="bg-very-light-gray overflow-hidden">
            <div className="container"> 
                <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 justify-content-center" data-anime-2>
                    {/* start rotate box item */}
                    <div className="col rotate-box-style-02 md-mb-30px">
                        <div className="w-100 min-h-300px text-center rotate-box to-left">
                            {/* start front side */}
                            <div className="w-100 h-100 overflow-hidden z-index-1 front-side bg-white w-100 border-radius-5px"> 
                                <div className="rotate-content-front z-index-2">
                                    <i className="line-icon-Geo2-Love icon-extra-large text-base-color mb-25px"></i>
                                    <div className="fs-19 text-dark-gray fw-500 mb-5px">Akshar Urja Marm Yog</div>
                                    <span className="w-60 lg-w-75 md-w-70 xs-w-60 d-block m-auto">5, Laxminarayan Nagar Society, Pune, Maharashtra, 411004</span>
                                </div>
                            </div>
                            {/* end front side */}
                            {/* start back side */}
                            <div className="w-100 h-100 overflow-hidden back-side cover-background border-radius-5px" style={{backgroundImage: "url(assets/images/logo/aumyog_logo_without_name_trans.png)"}}>
                                <div className="opacity-light bg-charcoal-blue"></div>
                                <div className="d-flex flex-column align-items-center justify-content-center h-100 z-index-2 rotate-content-back p-15"> 
                                    <a href="#location" className="btn btn-medium btn-round-edge btn-gradient-emerald-blue-emerald-green section-link">Location map</a>
                                </div>
                                {/* end back side */}
                            </div>
                        </div>    
                    </div>
                    {/* end rotate box item */}   
                    {/* start rotate box item */}
                    <div className="col rotate-box-style-02 md-mb-30px">
                        <div className="w-100 min-h-300px text-center rotate-box to-left">
                            {/* start front side */}
                            <div className="w-100 h-100 overflow-hidden z-index-1 front-side bg-white w-100 border-radius-5px"> 
                                <div className="rotate-content-front z-index-2">
                                    <i className="line-icon-Headset icon-extra-large text-base-color mb-25px"></i>
                                    <div className="fs-19 text-dark-gray fw-500 mb-5px">Let's talk with us</div>
                                    <span className="w-60 lg-w-75 md-w-70 xs-w-60 d-block m-auto">Phone: +91 99223 71107</span>
                                </div>
                            </div>
                            {/* end front side */}
                            {/* start back side */}
                            <div className="w-100 h-100 overflow-hidden back-side cover-background border-radius-5px" style={{backgroundImage:"url(assets/images/demo-startup-contact-02.jpg)"}}>
                                <div className="opacity-light bg-charcoal-blue"></div>
                                <div className="d-flex flex-column align-items-center justify-content-center h-100 z-index-2 rotate-content-back p-15"> 
                                    <a href="tel:+1-800-222-000" className="btn btn-medium btn-round-edge btn-gradient-emerald-blue-emerald-green">Talk with us</a>
                                </div>
                                {/* end back side */}
                            </div>
                        </div>    
                    </div>
                    {/* end rotate box item */} 
                    {/* start rotate box item */}
                    <div className="col rotate-box-style-02 md-mb-30px">
                        <div className="w-100 min-h-300px text-center rotate-box to-left">
                            {/* start front side */}
                            <div className="w-100 h-100 overflow-hidden z-index-1 front-side bg-white w-100 border-radius-5px"> 
                                <div className="rotate-content-front z-index-2">
                                    <i className="line-icon-Mail-Read icon-extra-large text-base-color mb-25px"></i>
                                    <div className="fs-19 text-dark-gray fw-500 mb-5px">Mail us at</div> 
                                    <span className="w-60 lg-w-75 md-w-70 xs-w-60 d-block m-auto">Committed staff members are ready to help you.</span>
                                </div>
                            </div>
                            {/* end front side */}
                            {/* start back side */}
                            <div className="w-100 h-100 overflow-hidden back-side cover-background border-radius-5px" style={{backgroundImage:"url(assets/images/demo-startup-contact-03.jpg)"}}>
                                <div className="opacity-light bg-charcoal-blue"></div>
                                <div className="d-flex flex-column align-items-center justify-content-center h-100 z-index-2 rotate-content-back p-15"> 
                                    <a href="mailto:srimantrashastra@gmail.com" className="btn btn-medium btn-round-edge btn-gradient-emerald-blue-emerald-green">Support desk</a>
                                </div>
                                {/* end back side */}
                            </div>
                        </div>    
                    </div>
                    {/* end rotate box item */} 
                </div>
            </div>
        </section>
        {/* end section */}
        {/* start section */}
        <section>
            <div className="container"> 
                <div className="row justify-content-center mb-3">
                    <div className="col-lg-7 text-center" data-anime-3>
                        <span className="alt-font text-uppercase fw-600 d-inline-block ls-1px">Feel free to get in touch!</span>
                        <h3 className="alt-font text-dark-gray fw-600 ls-minus-1px shadow-none" data-shadow-animation="true" data-animation-delay="1000">How we can <span className="text-highlight fw-800">help<span className="bg-gradient-emerald-blue-emerald-green h-8px bottom-10px opacity-6 separator-animation"></span></span> you?</h3>
                    </div>
                </div>
                <div className="row row-cols-md-1 justify-content-center">
                    <div className="col-xl-9 overflow-hidden">
                        {/* start contact form */}
                        <form action="email-templates/contact-form.php" method="post" className="row contact-form-style-02" data-anime='{ "translateX": [0, 0], "opacity": [0,1], "duration": 600, "delay": 250, "staggervalue": 300, "easing": "easeOutQuad" }'>
                            <div className="col-md-6 mb-30px">
                                <input className="input-name form-control required" type="text" name="name" placeholder="Your name*" />
                            </div>
                            <div className="col-md-6 mb-30px">
                                <input className="form-control required" type="email" name="email" placeholder="Your email address*" />
                            </div>
                            <div className="col-md-6 mb-30px">
                                <input className="form-control" type="tel" name="phone" placeholder="Your phone" />
                            </div>
                            <div className="col-md-6 mb-30px">
                                <input className="form-control" type="text" name="subject" placeholder="Your subject" />
                            </div>
                            <div className="col-md-12 mb-30px">
                                <textarea className="form-control" cols="40" rows="4" name="comment" placeholder="Your message"></textarea>
                            </div>
                            <div className="col-xl-6 col-md-7 last-paragraph-no-margin">
                                <p className="text-center text-md-start fs-15 lh-28">We are committed to protecting your privacy. We will never collect information about you without your explicit consent.</p>
                            </div>
                            <div className="col-xl-6 col-md-5 text-center text-md-end sm-mt-20px">
                                <input type="hidden" name="redirect" value="" />
                                <button className="btn btn-gradient-emerald-blue-emerald-green btn-small btn-round-edge submit" type="submit">Send message</button>
                            </div>
                            <div className="col-12">
                                <div className="form-results mt-20px d-none"></div>
                            </div>
                        </form>
                        {/* end contact form */}
                    </div>
                </div>
            </div>
        </section>
        {/* end section */}
        {/* start section */}
        <section className="p-0" id="location" data-anime='{ "translate": [0, 0], "opacity": [0,1], "duration": 600, "delay": 100, "staggervalue": 300, "easing": "easeOutQuad" }'>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-12 p-0">
                        {/* <div id="map" className="map" data-map-options='{ "lat": 18.502009, "lng": 73.831278, "style": "Silver", "marker": { "type": "HTML", "color": "#00ce9b" }, "popup": { "defaultOpen": true, "html": "<div className=infowindow><strong className=\"mb-3 d-inline-block alt-font fw-700\">Aumyog</strong><p className=\"alt-font\">5, Laxminarayan Nagar Society, Pune, 411004, India</p></div><div className=\"google-maps-link alt-font\"> <a aria-label=\"View larger map\" target=\"_blank\" jstcache=\"31\" href=\"https://maps.google.com/maps?ll=-37.805688,144.962312&amp;z=17&amp;t=m&amp;hl=en-US&amp;gl=IN&amp;mapclient=embed&amp;cid=13153204942596594449\" jsaction=\"mouseup:placeCard.largerMap\">VIEW LARGER MAP</a></div>" } }'></div> */}
                        <div id="map" className="map" data-map-options='{ "lat": 18.502009, "lng": 73.831278, "style": "Silver", "marker": { "type": "HTML", "color": "#00ce9b" }, "popup": { "defaultOpen": true, "html": "<div className=infowindow><strong className=\"mb-3 d-inline-block alt-font fw-700\">Aumyog</strong><p className=\"alt-font\">5, Laxminarayan Nagar Society, Pune, 411004, India</p></div><div className=\"google-maps-link alt-font\"> <a aria-label=\"View larger map\" target=\"_blank\" jstcache=\"31\"  href=\"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1891.797350327132!2d73.831278!3d18.502009!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bff27c5c1d41%3A0x2206beac29c42302!2sLaxminarayan%20Nagar%20Building%20No.%203!5e0!3m2!1sen!2sin!4v1729602947755!5m2!1sen!2sin" jsaction=\"mouseup:placeCard.largerMap\">VIEW LARGER MAP</a></div>" } }'></div>
                    </div>
                </div>
            </div>
        </section>
        {/* end section */}

        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return { data: state.dCandidate.list };
};

const mapActionToProps = {
    fetchData: actions.fetchallContact
}

export default connect(mapStateToProps, mapActionToProps)(Contact);